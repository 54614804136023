import React from "react";
import Typed from "react-typed";
import { Link } from "react-scroll";

const Header = ({ darkMode }) => {
  return (
    <div
      id="home"
      className={darkMode ? "header-wraper" : "header-wraperLight"}
    >
      <div className="main-info">
        <h1 className={darkMode ? "" : "text-light"}>
          Your Vision, My Creation, Custom Websites That Deliver Results
        </h1>
        <Typed
          className={darkMode ? "typed-text" : "typed-text-light"}
          strings={[
            "Web Development",
            "Web Application",
            "Business Website",
            "landing Page",
            "E-commerce",
          ]}
          typeSpeed={40}
          backSpeed={60}
          loop
        />

        <Link
          smooth={true}
          to="contacts"
          offset={-110}
          href="#"
          className={darkMode ? "btn-main-offer" : "btn-main-offer-light"}
        >
          Contact Me
        </Link>
      </div>
    </div>
  );
};

export default Header;
