import { GithubOutlined } from "@ant-design/icons";
import React from "react";
import { useNavigate } from "react-router-dom";
import { LinkedinIcon } from "react-share";

const Footer = ({ darkMode }) => {
  const navigate = useNavigate();

  return (
    <div className={darkMode ? "footer" : "footer-light"}>
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="d-flex">{/* <p>Adress: Tirane Albania</p> */}</div>
            <div className="d-flex">
              <a href="https://wa.me/32487290915">WhatsApp: +32487290915</a>
            </div>
            <div className="d-flex">
              <a href="mailto:bruno.wydooghe6@gmail.com">
                <p>bruno.wydooghe6@gmail.com</p>
              </a>
            </div>
          </div>
          <div className="col-lg-3 col-md-2 col-sm-6">
            <div className="row">
              <div className="col">
                <div className="footer-nav" onClick={() => navigate("/")}>
                  Home
                </div>
                <br />
                <div
                  className="footer-nav"
                  onClick={() => navigate("/aboutme")}
                >
                  About me
                </div>
                <br />
                <div
                  className="footer-nav"
                  onClick={() => navigate("/services")}
                >
                  Services
                </div>
              </div>
              <div className="col">
                {/* <a
                  className="footer-nav"
                  href="#"
                  onClick={() => navigate("/offers")}
                >
                  Experience
                </a> */}
                <br />
                <div
                  className="footer-nav"
                  onClick={() => navigate("/portfolio")}
                >
                  Portfolio
                </div>
                <br />
                <div
                  className="footer-nav"
                  onClick={() => navigate("/contacts")}
                >
                  Contacts
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-md-5 col-sm-6 align-items-center">
            <div className="d-flex justify-content-center">
              <GithubOutlined
                style={{
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "#b4bac3",
                  width: 36,
                }}
                size={36}
                className="mx-3"
                onClick={() => window.open("https://github.com/brunowydooghe/")}
              />

              <LinkedinIcon
                className="mx-3"
                style={{
                  cursor: "pointer",
                }}
                size={36}
                onClick={() =>
                  window.open(
                    "https://www.linkedin.com/in/bruno-wydooghe-64a5781a5/"
                  )
                }
              />

              {/* <EmailIcon className="mx-3" size={36}/> */}
            </div>
            <p className="pt-3 text-center">
              Copyright&copy;
              {new Date().getFullYear()}&nbsp;Bruno Wydooghe | All Rights
              Reserved
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
