import {
  Architecture,
  Dashboard,
  DonCarlos,
  // Ecommerce,
  // NftPage,
  OliveOilCompany,
  PetCompany,
  ProntoIntervento,
  // Resto,
  TravelingWebsite,
  WineStore,
} from "../../images";

export const ProgramData = [
  {
    title: "Restaurant Website",
    description: "Restaurant Don Carlos",
    link: "https://www.ristorantedoncarlos.com/",
    fullWebsite: true,
    img: DonCarlos,
    id: "22",
  },
  {
    title: "Services Website",
    description: "Plumber Electrician Locksmith Services Website",
    link: "https://pronto-intervento24.com",
    img: ProntoIntervento,
    id: "2",
    fullWebsite: true,
  },
  {
    title: "Menu Dashboard",
    description:
      "Demo Restaurant menu with dashboard for admin to update menu in real time try user name: admin , pasword: 123",
    link: "https://demo-qr-code-menu.netlify.app/",
    img: Dashboard,
    id: "1",
    fullWebsite: true,
  },
  // {
  //   title: "E-commerce website",
  //   description: "Landing Page for new buissnes",
  //   link: "https://web-bulders-commerce.netlify.app",
  //   fullWebsite: false,

  //   img: Ecommerce,
  //   id: "3",
  // },
  {
    title: "Company website",
    description: "Website for international olive oil compnay",
    link: "https://en.harrandoandco.com",
    fullWebsite: true,
    img: OliveOilCompany,
    id: "4",
  },
  {
    title: "Pet care website",
    description: "Full website for pet care buissnes",
    link: "https://www.platinumgroomingandboarding.com",
    fullWebsite: true,
    img: PetCompany,
    id: "5",
  },
  {
    title: "Traveling website",
    description: "Full website for traveling buissnes",
    link: "https://traveling-website.netlify.app",
    fullWebsite: false,

    img: TravelingWebsite,
    id: "6",
  },
  {
    title: "Wine store website",
    description: "Wine store promotion website",
    link: "https://wine-showroom.netlify.app",
    fullWebsite: false,

    img: WineStore,
    id: "7",
  },
  {
    title: "Appel store website",
    description: "Appel store demo promotion website",
    link: "https://iphone-store99.netlify.app",
    fullWebsite: false,

    img: PetCompany,
    id: "8",
  },
  {
    title: "Architecture website",
    description: "Architecture promotion website",
    link: "https://architecture-build.netlify.app",
    img: Architecture,
    id: "9",
    fullWebsite: false,
  },
];
