import React, { useEffect } from "react";
import author from "../../images/bruno1.jpg";
// import Certifications from "./Certifications";
import "./aboutMe.scss";

const AboutMe = ({ darkMode }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div id="About Me" className="container py-5">
        <div className="row">
          <div className="col-lg-6 col-xm-12">
            <div className="photo-wrap mb-5">
              <img className="profile-img" src={author} alt="author..." />
            </div>
          </div>
          <div className="col-lg-6 col-xm-12">
            <h1 className={darkMode ? "about-heading" : "about-heading-light"}>
              About Me
            </h1>
            <p>
              As a React.js developer, I am passionate about creating dynamic
              and engaging web applications that deliver results for my clients.
              With several years of experience in React.js development, I have a
              proven track record of delivering high-quality projects on time
              and on budget.
            </p>
            <p>
              I am skilled in both front-end and back-end development, with a
              deep understanding of React.js, JavaScript, HTML, and CSS. I
              specialize in creating responsive, user-friendly, and intuitive
              user interfaces that are optimized for performance and speed.
            </p>
            <p>
              My approach to development is collaborative, and I work closely
              with my clients to understand their needs and goals. I am
              committed to delivering projects that exceed expectations, and I
              am always looking for new and innovative ways to enhance the user
              experience.
            </p>
            <p>
              In addition to my technical skills, I am also a strong
              communicator and problem solver. I thrive in fast-paced
              environments, and I am always up for a new challenge. If you are
              looking for a reliable, skilled, and dedicated React.js developer,
              Contact Me!
            </p>
          </div>
        </div>
      </div>
      {/* <Certifications darkmode={darkMode} /> */}
    </>
  );
};

export default AboutMe;
