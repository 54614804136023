import Particles from "react-particles-js";
import Header from "./Header";
import Services from "../servicesPage/Services";
import Testimonials from "./Testimonials";
import Contacts from "../contacts/Contacts";
import { useEffect } from "react";
import { useMediaQuery } from "react-responsive";

function HomePage({ darkMode }) {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 980px)" });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {darkMode ? (
        <Particles
          className="particles-canvas"
          params={{
            particles: {
              lineLinked: {
                width: 3,
              },
              line_linked: {
                width: 3,
                opacity: 0.8,
              },
              number: {
                max: isTabletOrMobile ? 20 : 250,
                valuer: 30,
                desity: {
                  enable: true,
                  valuer_area: 1200,
                },
              },
              shape: {
                type: "circle",
                stroke: {
                  width: isTabletOrMobile ? 18 : 30,
                  color: "#f9ab00",
                },
              },
            },
          }}
        />
      ) : (
        <Particles
          className="particles-canvas-light"
          params={{
            particles: {
              lineLinked: {
                color: "#fff",
                width: 3,
              },
              line_linked: {
                color: "#fff",
                width: 3,
                opacity: 0.8,
              },
              number: {
                valuer: 30,
                max: isTabletOrMobile ? 20 : 250,
                desity: {
                  enable: true,
                  valuer_area: 1200,
                },
              },
              shape: {
                type: "circle",
                stroke: {
                  width: isTabletOrMobile ? 18 : 30,
                  opacity: 0.8,
                  color: "#fff",
                },
              },
            },
          }}
        />
      )}
      <Header {...{ darkMode }} />
      <Services {...{ darkMode }} />
      <Testimonials {...{ darkMode }} />
      <Contacts {...{ darkMode }} />
    </>
  );
}

export default HomePage;
