import "bootstrap/dist/css/bootstrap.min.css";
import "antd/dist/antd.css";
import Services from "./components/servicesPage/Services";
import Contacts from "./components/contacts/Contacts";
import { Route, Routes, useNavigate } from "react-router-dom";
import HomePage from "./components/homePage/HomePage";
import AboutMe from "./components/aboutMe/AbooutMe";
import Experience from "./components/myOffers/Experience";
import Protfolio from "./components/portfolio/Portfolio";
import { useState } from "react";
import { Layout } from "./components/Layout/Layout";
import "./App.css";
import { Button, Result } from "antd";

function App() {
  const local = window.localStorage.getItem("mode");
  const navigate = useNavigate();
  const localValue = local === "false" ? false : true;

  const [darkMode, setDarkMode] = useState(localValue);

  return (
    <Routes>
      <Route element={<Layout {...{ darkMode, setDarkMode }} />}>
        <Route path="/" element={<HomePage {...{ darkMode }} />} />
        <Route path="/aboutme" element={<AboutMe {...{ darkMode }} />} />
        <Route path="/services" element={<Services {...{ darkMode }} />} />
        <Route path="/offers" element={<Experience {...{ darkMode }} />} />
        <Route path="/portfolio" element={<Protfolio {...{ darkMode }} />} />
        <Route path="/contacts" element={<Contacts {...{ darkMode }} />} />
        <Route
          path="*"
          element={
            <Result
              className={darkMode ? "notFound" : "notFound-light"}
              status="403"
              title="Page not found"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Button onClick={() => navigate("/")} type="primary">
                  {" "}
                  Back Home
                </Button>
              }
            />
          }
        />
      </Route>
    </Routes>
  );
}

export default App;
