import React, { useEffect } from "react";
import restaurant from "../../images/restaurant.png";
import nftImage from "../../images/nft-landing-page.png";
import { Dashboard, Ecommerce, QRCode } from "../../images";
import "./experience.scss";

const Experience = ({ darkMode }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div
      id="Experience"
      className={darkMode ? "experience" : "experience-light"}
    >
      <div className="d-flex justify-content-center my-5">
        <h1>My Offers</h1>
      </div>
      <div className="container experience-wrapper">
        <div className="timeline-block timeline-block-left">
          <div className={darkMode ? "marker" : "marker-light"}></div>
          <div
            className={darkMode ? "timeline-content" : "timeline-content-light"}
          >
            <h3>Order now your business website</h3>
            <img
              alt="promo-img"
              className="promo-img"
              onClick={() =>
                window.open("https://restaurant-promotion.netlify.app/")
              }
              src={restaurant}
              width="100%"
              height={240}
              style={{ borderRadius: 10, cursor: "pointer" }}
            />
          </div>
        </div>
        {/*  */}
        <div className="timeline-block timeline-block-right">
          <div className={darkMode ? "marker" : "marker-light"}></div>
          <div
            className={darkMode ? "timeline-content" : "timeline-content-light"}
          >
            <h3>Order now your Nft landing page</h3>
            <img
              alt="promo-img"
              className="promo-img"
              onClick={() => window.open("https://nft-home-page.netlify.app/")}
              src={nftImage}
              width="100%"
              height={240}
              style={{ borderRadius: 10, cursor: "pointer" }}
            />
          </div>
        </div>
        {/*  */}
        <div className="timeline-block timeline-block-left">
          <div className={darkMode ? "marker" : "marker-light"}></div>
          <div
            className={darkMode ? "timeline-content" : "timeline-content-light"}
          >
            <h3>Order now your E-commerce landing page</h3>
            <img
              alt="promo-img"
              className="promo-img"
              onClick={() =>
                window.open("https://web-bulders-commerce.netlify.app/")
              }
              src={Ecommerce}
              width="100%"
              height={240}
              style={{ borderRadius: 10, cursor: "pointer" }}
            />
          </div>
        </div>
        <div className="timeline-block timeline-block-right">
          <div className={darkMode ? "marker" : "marker-light"}></div>
          <div
            className={darkMode ? "timeline-content" : "timeline-content-light"}
          >
            <h3>Order now your Costumized Dashboard</h3>
            <img
              alt="promo-img"
              className="promo-img"
              onClick={() =>
                window.open("https://dashboard-webbuilders.netlify.app/")
              }
              src={Dashboard}
              width="100%"
              height={240}
              style={{ borderRadius: 10, cursor: "pointer" }}
            />
          </div>
        </div>
        <div className="timeline-block timeline-block-left">
          <div className={darkMode ? "marker" : "marker-light"}></div>
          <div
            className={darkMode ? "timeline-content" : "timeline-content-light"}
          >
            <h3>QR Code for online restaurant menu (mobile version)</h3>
            <img
              alt="promo-img"
              className="promo-img"
              onClick={() => window.open("https://doncarlos-menu.netlify.app/")}
              src={QRCode}
              width="70%"
              height={240}
              style={{ borderRadius: 10, cursor: "pointer" }}
            />
          </div>
        </div>
        {/*  */}
        {/* <div className="timeline-block timeline-block-right">
                    <div className={darkMode ? "marker" : "marker-light"}></div>
                    <div className="timeline-content">
                        <h3>2018-2022 Seo-Optimization </h3>
                        <p>We offer best Google Ads to make sure you get best position in google , digital marketing and social media menagement !</p>
                    </div>
                </div> */}
      </div>
    </div>
  );
};

export default Experience;
