import { Card } from "antd";
import React from "react";
import { Bruno, Label } from "../../images/";
import {
  LinkedinOutlined,
  LinkOutlined,
  GithubOutlined,
} from "@ant-design/icons";
import "./RightSider.scss";

function RightSider({ darkMode, setPortfoliodata, ProgramData }) {
  const filterFullWebstie = () => {
    const data = ProgramData?.filter((el) => el.fullWebsite === true);
    setPortfoliodata(data);
  };

  const filterDemoWebstie = () => {
    const data = ProgramData?.filter((el) => el.fullWebsite === false);
    setPortfoliodata(data);
  };
  return (
    <div className={darkMode ? "RightSider" : "RightSider-light"}>
      <div className="card-blog">
        <Card>
          <img className="imgae" src={Bruno} alt="bruno" />
          <h2
            style={{ color: darkMode ? "#b4bac3" : "#191970", paddingTop: 10 }}
          >
            Bruno Wydooghe
          </h2>
          <h3 style={{ color: darkMode ? "#b4bac3" : "#191970" }}>
            Web Developer
          </h3>
          <div className="icons">
            {/* <FacebookOutlined
              onClick={window.open("https://www.brunowydooghe.com/")}
            /> */}
            <GithubOutlined
              style={{ fill: "red" }}
              onClick={() => window.open("https://github.com/brunowydooghe/")}
            />
            <LinkedinOutlined
              onClick={() =>
                window.open(
                  "https://www.linkedin.com/in/bruno-wydooghe-64a5781a5/"
                )
              }
            />
            <LinkOutlined
              onClick={() => window.open("https://www.brunowydooghe.com/")}
            />
          </div>
        </Card>
      </div>
      <div className="categories">
        <div className="item-category" onClick={() => filterFullWebstie()}>
          <img className="label" src={Label} alt="promo-img" /> Full Website
        </div>
        <div className="item-category" onClick={() => filterDemoWebstie()}>
          <img className="label" src={Label} alt="promo-img" /> Demo Website
        </div>
      </div>
    </div>
  );
}

export default RightSider;
