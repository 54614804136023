import { Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";

const Testimonials = ({ darkMode }) => {
  return (
    <div
      id="testimonials"
      className={darkMode ? "testimonials" : "testimonials-light"}
    >
      <h1>Web Builders</h1>
      <Link to="/portfolio">
        <Button className={darkMode ? "portfolio-btn" : "portfolio-light-btn"}>
          PORTFOLIO
        </Button>
      </Link>
    </div>
  );
};

export default Testimonials;
