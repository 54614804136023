import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Logo, LogoLight } from "../../images";
import { useMediaQuery } from "react-responsive";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Switch } from "./Switch";
import { Drawer } from "antd";

export const NavBar = ({ darkMode, setDarkMode }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 980px)" });

  const [menuModal, setMenuModal] = useState(false);

  const navigation = () => (
    <div className={darkMode ? "navigationDark" : "naviigationLight"}>
      <div
        className={path === "/" ? "nav-item active" : "nav-item"}
        onClick={() => {
          navigate("/");
          setMenuModal(false);
        }}
      >
        Home
      </div>
      <div
        smooth={true}
        to="About Me"
        offset={-110}
        className={path === "/aboutme" ? "nav-item active" : "nav-item"}
        onClick={() => {
          navigate("/aboutme");
          setMenuModal(false);
        }}
      >
        About Me
      </div>
      <div
        smooth={true}
        to="Services"
        offset={-110}
        className={path === "/services" ? "nav-item active" : "nav-item"}
        onClick={() => {
          navigate("/services");
          setMenuModal(false);
        }}
      >
        Services
      </div>
      {/* <div
        smooth={true}
        className={path === "/offers" ? "nav-item active" : "nav-item"}
        onClick={() => {
          navigate("/offers");
          setMenuModal(false);
        }}
      >
        My offers
      </div> */}

      <div
        smooth={true}
        className={path === "/portfolio" ? "nav-item active" : "nav-item"}
        to="/portofolio"
        offset={-110}
        onClick={() => {
          navigate("/portfolio");
          setMenuModal(false);
        }}
      >
        Portfolio
      </div>

      <div
        smooth={true}
        className={path === "/contacts" ? "nav-item active" : "nav-item"}
        onClick={() => {
          navigate("/contacts");
          setMenuModal(false);
        }}
      >
        Contacts
      </div>
    </div>
  );

  return (
    <div className={darkMode ? "nav-dark" : "nav-light"}>
      <div className="logo-contanier" onClick={() => navigate("/")}>
        <img src={darkMode ? Logo : LogoLight} alt="logo" />
      </div>
      {isTabletOrMobile ? (
        <div className="mobile-icon-container">
          <Switch {...{ darkMode, setDarkMode }} />
          <div
            onClick={() => setMenuModal(true)}
            className={
              darkMode ? "hamburger-contanier" : "hamburger-contanier-light"
            }
          >
            <FontAwesomeIcon
              icon={faBars}
              style={{ color: darkMode ? "#343a40" : "#fff" }}
              width={25}
              height={25}
            />
          </div>
          {menuModal && (
            <Drawer
              className={darkMode ? "dark-drawer" : "light-drawer"}
              title="Navigation Menu"
              placement="right"
              onClose={() => setMenuModal(false)}
              width={"100%"}
              open={menuModal}
            >
              {navigation()}
            </Drawer>
          )}
        </div>
      ) : (
        <>
          {navigation()}
          <Switch {...{ darkMode, setDarkMode }} />
        </>
      )}
    </div>
  );
};
